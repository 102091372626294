import ReactDom from 'react-dom';
import {NewsletterSignup} from '~/footer/NewsletterSignup';

const subscribeMessage = 'Party planning tips, gift ideas, special partner offers, and more!';
const root = document.getElementById('newsletter-signup-root');
if (root) {
  ReactDom.render(
    <NewsletterSignup
      isSmallButton={!window.is_mobile_site}
      subscribeButtonMessage="Sign up"
      subscribeMessage={subscribeMessage}
    />,
    root
  );
}
