import React, {useState, useEffect} from 'react';

const defaultSubscribeMessage = 'Join our email list for party tips, updates, and offers.';

export function NewsletterSignup(props) {
  const {
    isSmallButton = false,
    subscribeMessage = defaultSubscribeMessage,
    subscribeButtonMessage = 'Submit',
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [messageText, setMessageText] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const ariaProps = {
    'aria-describedby': `${isSuccess ? 'success' : 'error'}_message`,
  };
  const feedbackBlock = document.getElementById('newsletter-feedback');

  useEffect(() => {
    if (showFeedback && !isSuccess) {
      ariaProps['aria-invalid'] = true;
    }
  }, [showFeedback, isSuccess]);

  const handleAjaxResponse = (message, isSuccess = true) => {
    console.log('message', message);
    setMessageText(message);
    setIsSuccess(isSuccess);
    setShowFeedback(true);
    evite.removeClass(feedbackBlock, 'hidden');
  };

  const subscribe = function (e) {
    e.preventDefault();

    const data = {
      email_addr: inputValue,
      marketing_source: 'site_footer',
    };

    $.ajax({
      url: '/ajax/subscribe/',
      dataType: 'json',
      type: 'POST',
      timeout: 10000,
      data,
      success(data) {
        handleAjaxResponse(data.message);
      },
      error(xhr, textStatus, errorThrown) {
        handleAjaxResponse(xhr.responseJSON.message, false);
      },
    });
  };

  const handleInputChange = (e) => {
    setShowFeedback(false);
    setMessageText('');
    setInputValue(e.target.value);
    evite.addClass(feedbackBlock, 'hidden');
  };

  return (
    <form className="evite-form" onSubmit={subscribe}>
      <ul id="newsletter-signup" className="newsletter-signup" role="presentation">
        <li className="font_28 subscribe-message" id="subscribe_message">
          {subscribeMessage}
        </li>
        <li className="newsletter-email">
          <p className="visually-hidden" id="enter_email">
            Enter your email address
          </p>
          <input
            data-qa-id="footer_email"
            className="newsletter-email-input"
            type="text"
            id="footer-email"
            placeholder="Enter your email address"
            aria-labelledby="subscribe_message enter_email"
            onChange={handleInputChange}
            value={inputValue}
            {...ariaProps}
          />
          <button
            type="submit"
            data-qa-id="footer_submit"
            className={`${isSmallButton ? 'small' : ''} secondary-button button secondary`}
            id="subscribe"
            disabled={!inputValue}
          >
            {subscribeButtonMessage}
          </button>
        </li>
        <li id="newsletter-feedback" className="newsletter-feedback hidden">
          <span
            id={`${isSuccess ? 'success' : 'error'}_message`}
            className={`${isSuccess ? 'success' : 'error'}-message`}
            role="region"
            aria-live="polite"
            aria-relevant="additions"
          >
            {messageText}
          </span>
        </li>
      </ul>
    </form>
  );
}
